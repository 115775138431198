import React, { useState, useEffect } from 'react'
import './priceList.scss';
import SearchIcon from "../assets/img/searchIcon.png";
import { useTranslation } from "react-i18next";
import useIsMobile from '../hooks/useIsMobile';

function Procelist() {
  let isMobile = useIsMobile()
  const { t } = useTranslation();
  const [btn, setBtn] = useState('Все-анализы')
  const [tabs, setTabs] = useState([
    {
      title: 'Все-анализы',
    }])
  const [filtered, setFiltered] = useState(tabs)
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [category, setCategory] = useState([]);
  const [analiz, setAnaliz] = useState();
  const [catId, setCatId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetch("https://backend.ttd.uz/api/?token=NTQ5NzU2MDc2MzI5Ok1XTXhOalE0TURrdE4yTTJPQzAwTURObExUZzVNamt0TURZME9URXlPRGs0TW1VeA", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setCategory(data);
      })
      .catch((error) => console.log(error));
  }, []);


  const setBtnHandler = (item) => {
    setBtn(item)
    setFiltered(tabs.filter(tab => tab.title !== item))
  }

  const changeCat = (item) => {
    setBtn(item?.categoryName)
    setCatId(item?.id) 
    setActiveAccordion(item?.categoryName);
  }

  useEffect(() =>{
    setCatId('0')
  }, [])

  useEffect(() => {
    if(catId?.length > 0){
      fetch(`https://backend.ttd.uz/api/?token=NTQ5NzU2MDc2MzI5Ok1XTXhOalE0TURrdE4yTTJPQzAwTURObExUZzVNamt0TURZME9URXlPRGs0TW1VeA&categoryId=${catId}`, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          setAnaliz(data);
        })
        .catch((error) => console.log(error));
    }
  }, [catId]);

  useEffect(() => {
    if (activeAccordion === null && category.length > 0) {
      const firstCategory = category[0];
      toggleAccordion(firstCategory?.categoryName);
      changeCat(firstCategory);
    }
  }, [activeAccordion, category]);

  const toggleAccordion = (categoryName) => {
    setActiveAccordion((prevActiveAccordion) =>
      prevActiveAccordion === categoryName ? null : categoryName
    );
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  
  const filteredAnaliz = analiz?.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  

  return (
    <>

      {/* /////// Price //////// */}

      <div className="priceList_wrape">
        <div className="container-xl px-0 price">
          <div className="price_header">
            <h1>{t('cost')} <b>{t('list')}</b> </h1>
            <div className='search_wrap d-flex'>
              <img src={SearchIcon} alt="" />
              <input type="text" placeholder={t('search_placeholder')} value={searchTerm} onChange={handleSearch} />
            </div>
          </div>
          {isMobile ? <div className="row_price">
            <div className="price_body1">
              <div className='activeBtnWrap'>
                <p className='activeBtn'>{btn}</p>
              </div>
              <div className="analiz_price">
                  <div className='search_wrap2 d-fle'>
                    <img src={SearchIcon} alt="" />
                    <input type="text" placeholder={t('search_placeholder')} value={searchTerm} onChange={handleSearch} />
                  </div>
                  <div className="analiz_price_header">
                    <p>{t('analiz_name')}</p>
                    <p>{t('price')}</p>
                  </div>
              </div>
            </div>
            <div className="price_body">
              <div className="price_body_inner">
                <div className="ex3">
                  <div className="analz_name">
                    <div className='activeBtnWrap2'>
                      <p className='activeBtn2'>{btn}</p>
                    </div>
                    {category.map((item,i) => 
                    <div>
                      <button key={i} className={`tab ${item.categoryName === btn ? 'activeTab1' : null}`} onClick={() => setBtnHandler(item.categoryName)}>{t(`${item.categoryName}`)}</button>
                    </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div> 
          : 
          <div className="row_price">
            <div className="price_body1">
              <div className='activeBtnWrap'>
                <p className='activeBtn'>{btn}</p>
              </div>
              <div className="analiz_price">
                  <div className='search_wrap2 d-fle'>
                    <img src={SearchIcon} alt="" />
                    <input type="text" placeholder={t('search_placeholder')} value={searchTerm} onChange={handleSearch} />
                  </div>
                  <div className="analiz_price_header">
                    <p>{t('analiz_name')}</p>
                    <p>{t('price')}</p>
                  </div>
              </div>
              <div className="analiz_price_body">
                <div className="ex4">
                  {filteredAnaliz?.map((item, j) => (
                    <span key={j}>
                      <p>{item.name}</p>
                      <b>{item.price}сум</b>
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="price_body">
              <div className="price_body_inner">
                <div className="ex3">
                  <div className="analz_name">
                    <div className='activeBtnWrap3'>
                      {/* <p className='activeBtn2'>{btn}</p> */}
                    </div>
                    {[{id:'0', categoryName: `${t('vse')}`}, ...category]?.map((item,i) => 
                    <div key={i}>
                      <button key={i} className={`tab ${item.categoryName === btn ? 'activeBtn' : null}`} onClick={() => changeCat(item)  }>{t(`${item.categoryName}`)}</button>
                    </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>}
        </div>
        <div className="price_mobile">
          <div className="price_mobile_wrapper">
            <div className="price_mobile_inner">
              <div className="accordion" id="accordionExample">
                {[{id:'0', categoryName: `${t('vse')}`}, ...category]?.map((categoryItem, i) => (
                  <div className="accordion-item" key={i}>
                    <button
                      className={`tab ${categoryItem.categoryName === activeAccordion ? 'activeBtn' : null}`}
                      onClick={() => {
                        toggleAccordion(categoryItem.categoryName); // Toggle the accordion
                        if (activeAccordion === categoryItem.categoryName) {
                          setCatId('');
                        } else {
                          changeCat(categoryItem);
                        }
                      }}
                    >
                      {t(`${categoryItem.categoryName}`)}
                    </button>
                    <div
                      id={`collapse${i}`}
                      className={`accordion-collapse collapse ${categoryItem.categoryName === activeAccordion ? 'show' : ''}`}
                      aria-labelledby={`heading${i}`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="analiz_price">
                        <div className='search_wrap d-flex'>
                            <img src={SearchIcon} alt="" />
                            <input type="text" placeholder={t('search_placeholder')} value={searchTerm} onChange={handleSearch} />
                          </div>
                          <div className="analiz_price_header">
                            <p>{t('analiz_name')}</p>
                            <p>{t('price')}</p>
                          </div>
                          <div className="ex4">
                            {filteredAnaliz?.map((item, j) =>
                                <span key={j}>
                                  <p>{item.name}</p>
                                  <b>{item.price}сум</b>
                                </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Procelist;

