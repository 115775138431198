import React from 'react'
import './footer.scss';
import Logo from "../../assets/img/newlogoWh.png"
import TgIcon from "../../assets/img/tg.png";
import InstaIcon from "../../assets/img/insta.png";
import FacebookIcon from "../../assets/img/facebook.png";
import PhoneIcon from "../../assets/img/Phone.png";

function Footer() {
  return (
    <div className="footer">
      <div className="container-xl px-0">
        <div className="row">
            <div className="col-md-3 logoFooter">
              <a href="/" className="footer-site-logo"><img src={Logo} alt="" /></a>
              <span>
                <img src={TgIcon} alt="" onClick={() => window.open('https://t.me/ttduz_bot', '_blank')} style={{ cursor: 'pointer' }}/>
                <img src={InstaIcon} alt="" onClick={() => window.open('https://www.instagram.com/ttd.clinic/', '_blank')} style={{ cursor: 'pointer' }}/>
                <img src={FacebookIcon} alt="" style={{ cursor: 'pointer' }}/>
              </span>
            </div>
            <div className="footerTabs">
            <div className=" firstCalFooter">
              <h3>Карта сайта</h3>
              <ul className="list-unstyled links linksFirstCal">
                <li><a href="#">О компании</a></li>
                <li><a href="#">Услуги</a></li>
                <li><a href="#">Фотогалерея</a></li>
                <li><a href="#">Акции</a></li>
                <li><a href="#">Контакты</a></li>
              </ul>
            </div>
            <div className=" firstCalFooter">
              <h3>Услуги</h3>
              <ul className="list-unstyled links linksFirstCal">
                <li><a href="#">УЗД</a></li>
                <li><a href="#">ЛОР</a></li>
                <li><a href="#">Гинеколог</a></li>
                <li><a href="#">Уролог</a></li>
                <li><a href="#">МСКТ</a></li>
              </ul>
            </div>
            <div className=" firstCalFooter">
              <h3>Наши контакты</h3>
                <ul className="list-unstyled links linksFirstCal contactLinks">
                  <li className='num'>
                    <span><img src={PhoneIcon} alt="" /></span>
                    <a className='contactTxt' href="tel:+998712079394">+998712079394</a>
                  </li>
                  <li className='tg'>
                    <span><img src={TgIcon} alt="" /></span>
                    <a className='contactTxt' href="https://t.me/ttduz_bot" target="_blank">@ttduzbot</a>
                  </li>
                  <li className='insta'>
                    <span><img src={InstaIcon} alt="" /></span>
                    <a className='contactTxt' href="https://www.instagram.com/ttd.clinic/" target="_blank">@ttdclinic</a>
                  </li>
                  {/* <li className='facebook'>
                    <span><img src={FacebookIcon} alt="" /></span>
                    <a className='contactTxt' href="#">TTD_clinic</a>
                  </li> */}
                </ul>
            </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
