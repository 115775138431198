import "./contact.scss";
import React, { useState } from 'react';
import {
  YMaps,
  Map,
  Placemark,
  FullscreenControl,
  GeolocationControl,
  TrafficControl,
  ZoomControl,
  RouteButton,
  TypeSelector,
} from "@pbe/react-yandex-maps";
import PlacemarkIcon from "../../assets/img/locationIcon.png";

const Mapscontact = () => {
  const [selectedPlacemarkIndex, setSelectedPlacemarkIndex] = useState(null); 
  var data = [
    {
      name: "Филлиал колл-центр",
      coordinates: [41.282662, 69.211199],
    },
    {
      name: "Филлиал Юнусабад",
      coordinates: [41.359284, 69.288764],
    },
    {
      name: "Филлиал Мирзо Улугбек",
      coordinates: [41.32861, 69.343768],
    },
    {
      name: "Филлиал Ц1",
      coordinates: [41.307308, 69.291310],
    },
    {
      name: "Филлиал Сергели",
      coordinates: [41.218605, 69.221894],
    },
  ];
  const handlePlacemarkClick = (index) => {
    const currentLocation = "";
    const destination = data[index].coordinates;
    const destinationString = destination.join(",");
    const encodedDestination = encodeURIComponent(destinationString);
    
    let mapLink = `https://yandex.com/maps/?rtext=${currentLocation}~${encodedDestination}`;
    
    window.open(mapLink, "_blank");
  };

  return (
    <YMaps>
      <div className="demo1">
        <Map
          defaultState={{
            center: [41.299496, 69.240074],
            zoom: 12,
          }}
        >
          {data.map((point, index) => (
            <Placemark
              options={{
                iconLayout: "default#image",
                iconImageHref: PlacemarkIcon,
                iconImageSize: [25, 35],
              }}
              key={index}
              geometry={point.coordinates}
              properties={{
                item: index,
              }}
              onClick={() => {
                handlePlacemarkClick(index);
                setSelectedPlacemarkIndex(index);
              }}
              state={{
                selected: index === selectedPlacemarkIndex
              }}
            />
          ))}
          {/* <FullscreenControl options={{ float: 'left'}} /> */}
          {/* <GeolocationControl options={{ float: 'right'}} /> */}
          {/* <TrafficControl options={{float: 'right'}} />
                    <ZoomControl options={{float: 'left'}} /> */}
          {/* <RouteButton options={{ float: 'right'}} /> */}
          {/* <TypeSelector options={{float: 'right'}} /> */}
        </Map>
      </div>
    </YMaps>
  );
};

export default Mapscontact;
