import './navbar.scss';
import { useState, useEffect } from 'react';
import Logo from "../../assets/img/newlogo.png";
import LogoMobile from "../../assets/img/newlogoWh.png";
import TgLogo from "../../assets/img/Vectortg.png";
import InstaLogo from "../../assets/images/icons/insta.svg";
import { useTranslation } from 'react-i18next';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import LanguageSwitcher from "../../component/LanguageSwitcher";

function OffcanvasExample() {
    const { t } = useTranslation();

    // const handleLanguageChange = (e) => {
    //     i18n.changeLanguage(e.target.value)
    // }

    const [fixed, setFixed] = useState(false)
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if(window.scrollY > 95) {
                setFixed(true) 
            } else{
                setFixed(false)
            }
        })
    }, [])

  return (
    <>
      {['md'].map((expand) => (
        <Navbar key={expand} expand={expand} className="navbar">
            <div className="container-xl">
                <div className="content">
                    <a className="navbar-brand" href="/"><img src={Logo} alt="" /></a>
                    <div className='addresNav'>
                         <h5>{t('clinic_labarotory')}</h5>
                         <p>{t('location')}</p>
                     </div>
                     <div className='contactNav'>
                         <p>{t('working_time')}</p>
                         <p>{t('phone')} <a href="tel:+998712079394">+998712079394</a></p>
                         <p className=''>{t('sets')}
                             <span>
                                <a href="https://t.me/ttduz_bot" target="_blank" style={{ cursor: 'pointer' }}><img src={TgLogo} alt="" /></a>
                                 <a href="https://www.instagram.com/ttd.clinic/" target="_blank" style={{ cursor: 'pointer' }}><img className='px-1' src={InstaLogo} alt="" /></a>
                             </span>
                         </p>
                     </div>
                </div>
            </div>
          <div className={fixed ? "fixedNav nav" : "nav"}>
            <div className="container-xl">
                <div className="mediaNav">
                    <Navbar.Brand href="/" className='logoMedia'>
                        <img className='px-0' src={LogoMobile} alt="" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                </div>
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${expand}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                    placement="end"
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                            <img className='px-0' src={LogoMobile} alt="" />
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="flex-grow-1 pe-3">
                            <Nav.Link href="/about" className='navText'>{t('about_company')}</Nav.Link>
                            <Nav.Link href="/usluga" className='navText'>{t('services')}</Nav.Link>
                            <Nav.Link href="/gallery" className='navText'>{t('fotogallery')}</Nav.Link>
                            <Nav.Link href="/aksiya" className='navText nav_aksiya'>{t('discount')}</Nav.Link>
                            <Nav.Link href="/contact" className='navText'>{t('contact')}</Nav.Link>
                            <LanguageSwitcher />
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </div>
          </div>
        </Navbar>
      ))}
    </>
  );
}

export default OffcanvasExample;
