import React from "react";
import "./aksiya.scss";
import TopImg from "../../assets/images/pageDiscount/m1.svg";
import ImgAksiya from "../../assets/images/pageDiscount/d1.svg";
import ImgAksiya2 from "../../assets/images/pageDiscount/d2.svg";
import ImgAksiya3 from "../../assets/images/pageDiscount/d3.svg";

function Aksiya() {
  const data = [
    {
      image: ImgAksiya,
      aksiya: "Акция",
      aksiya_name: "УЗД щитовидной железы бесплатно",
      aksiya_time: "Каждый день с 15.00 до 7.00",
    },
    {
      image: ImgAksiya2,
      aksiya: "Акция",
      aksiya_name: "Общий анализ крови + глюкоза бесплатно",
      aksiya_time: "Каждый день на голодный желудок",
    },
    {
      image: ImgAksiya3,
      aksiya: "Акция",
      aksiya_name: "10% скидка каждое воскресенье",
      aksiya_time: "Включая праздничные дни",
    },
  ];

  return (
    <>
      <div className="aksiyaPage">
        <div className="aksiyaPageTop">
          <div className="container-xl px-0">
            <div className="aksiya_top_row row">
              <div className="top_content">
                <h1>
                  Акции <br /> <b>скидки и бонусы</b>
                </h1>
                <p>
                  Радуем наших любимых пациентов вкусными ценами, скидками и
                  бонусами
                </p>
              </div>
              <img src={TopImg} alt="" />
            </div>
          </div>
        </div>
        <div className="aksiyaContent">
          <div className="container-xl">
            <div className="aksiyaRow row">
              {data.map((item, i) => {
                return (
                  <div className="col">
                    <h2>{item.aksiya}</h2>
                    <img src={item.image} alt="" />
                    <div className="col_content">
                      <h1>{item.aksiya}</h1>
                      <h3>{item.aksiya_name}</h3>
                      <p>{item.aksiya_time}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Aksiya;
