import ruTranslations from "../locales/ru.json";
import uzTranslations from "../locales/uz.json";

export const i18nConfig = {
	interpolation: { escapeValue: false }, // React already does escaping
	lng: "ru", // Default language
	resources: {
		ru: {
			translation: ruTranslations,
		},
		uz: {
			translation: uzTranslations,
		},
	},
};
