import React, { useState } from 'react';
import "./map.scss";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import placemarkicon from "../../assets/img/locationIcon.png";

const Maps = () => {
  const [selectedPlacemarkIndex, setSelectedPlacemarkIndex] = useState(null); 

  const data = [
    {
      name: "Филлиал Чиланзар",
      coordinates: [41.282662, 69.211199],
    },
    {
      name: "Филлиал Юнусабад",
      coordinates: [41.359284, 69.288764],
    },
    {
      name: "Филлиал Мирзо Улугбек",
      coordinates: [41.32861, 69.343768],
    },
    {
      name: "Филлиал Ц1",
      coordinates: [41.308482, 69.290438],
    },
    {
      name: "Филлиал Сергели",
      coordinates: [41.221051, 69.222823],
    },
  ];

  const handlePlacemarkClick = (index) => {
    const currentLocation = "";
    const destination = data[index].coordinates;
    const destinationString = destination.join(",");
    const encodedDestination = encodeURIComponent(destinationString);
    
    let mapLink = `https://yandex.com/maps/?rtext=${currentLocation}~${encodedDestination}`;
    
    window.open(mapLink, "_blank");
  };
  

  return (
    <YMaps>
      <div className="demo">
        <Map
          defaultState={{
            center: [41.308482, 69.290438],
            zoom: 11,
          }}
        >
          {data.map((point, index) => (
            <Placemark
              options={{
                iconLayout: "default#image",
                iconImageHref: placemarkicon,
                iconImageSize: [25, 35],
              }}
              key={index}
              geometry={point.coordinates}
              properties={{
                item: index,
              }}
              onClick={() => {
                handlePlacemarkClick(index);
                setSelectedPlacemarkIndex(index);
              }}
              state={{
                selected: index === selectedPlacemarkIndex
              }}
            />
          ))}
        </Map>
      </div>
    </YMaps>
  );
};

export default Maps;
