import React, { useEffect } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import "./dropdownItem.css";

const LanguageSwitcher = () => {
  const { i18n, t } = useTranslation();
  const handleLanguageChange = (language) => {
    localStorage.setItem("i18nextLng", language);
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("i18nextLng"));
  }, []);

  return (
    <div className="lang_dropdown d-flex justify-content-center" style={{ marginTop: "-3px" }}>
      <ul className="nav nav-pills">
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle language"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t("language")}
          </a>
          <div className="dropdown-menu">
            <a
              className="dropdown-item languages"
              onClick={() => handleLanguageChange("ru")}
            >
              <img
                className="w-20 mr-2"
                style={{ width: "20px", marginRight: "2px" }}
                src={require("../assets/img/flags/ru.svg").default}
                alt="fake_url"
              />
              {t("russian")}
            </a>
            <div className="dropdown-divider" />
            <a
              className="dropdown-item languages"
              onClick={() => handleLanguageChange("uz")}
            >
              <img
                className="w20 mr-2"
                style={{ width: "20px", marginRight: "2px" }}
                src={require("../assets/img/flags/uz.svg").default}
                alt="fake_url"
              />
              {t("uzbek")}
            </a>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default withTranslation()(LanguageSwitcher);
