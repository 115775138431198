import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import StarRatings from "react-star-ratings";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Map from "../map/map";
import "./home.scss";
import PriceList from "../../component/priceList";
import homeImg from "../../assets/images/page1/home.svg";
import CardImg from "../../assets/images/page1/Sonographyamic1.svg";
import CardImg2 from "../../assets/images/page1/beakechemistry-amico2.svg";
import CardImg3 from "../../assets/images/page1/Invitroamico1.svg";
import CardImg4 from "../../assets/images/page1/CTscan-amico1.svg";
import DiscountImg from "../../assets/images/page1/discount.svg";
import DocImg from "../../assets/images/page1/Health1.svg";
import DocImg2 from "../../assets/images/page1/Health2m.svg";
import ClinicImg from "../../assets/images/page1/Hospitalbuilding.svg";
import TtdFoto2 from "../../assets/images/page1/Hospitalbuildingm.svg";
import NewsCard from "../../assets/img/news5.png";
import NewsCard2 from "../../assets/img/news6.png";
import NewsCard3 from "../../assets/img/newsimg3.png";
import OtzivCard from "../../assets/img/otzivCard.png";
import OtzivCard2 from "../../assets/img/otzivCard2.png";

const Home = () => {
  const { t } = useTranslation();

  const settings = {
    infinite: true,
    slidesToShow: 3.5,
    slidesToScroll: 3.5,
    speed: 500,
    dots: false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          rows: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 2,
        },
      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 2,
          // slidesPerRow: 2
        },
      },
    ],
    afterChange: function (index) {
      console.log(
        `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
      );
    },
  };

  const data = [
    {
      image: OtzivCard,
      name: "Зайцева Ольга Дмитриевна",
      rating: 5,
      caption:
        "Очень понравилось качество обслуживания, хочу выразить отдельную благодарность администрации лаборатории",
    },
    {
      image: OtzivCard2,
      name: "Тереньтьева Виктория",
      rating: 4,
      caption:
        "Очень понравилось качество обслуживания, хочу выразить отдельную благодарность администрации лаборатории",
    },
    {
      image: OtzivCard,
      name: "Зайцева Ольга Дмитриевна",
      rating: 5,
      caption:
        "Очень понравилось качество обслуживания, хочу выразить отдельную благодарность администрации лаборатории",
    },
    {
      image: OtzivCard2,
      name: "Тереньтьева Виктория",
      rating: 4,
      caption:
        "Очень понравилось качество обслуживания, хочу выразить отдельную благодарность администрации лаборатории",
    },
    {
      image: OtzivCard,
      name: "Зайцева Ольга Дмитриевна",
      rating: 5,
      caption:
        "Очень понравилось качество обслуживания, хочу выразить отдельную благодарность администрации лаборатории",
    },
    {
      image: OtzivCard2,
      name: "Тереньтьева Виктория",
      rating: 4,
      caption:
        "Очень понравилось качество обслуживания, хочу выразить отдельную благодарность администрации лаборатории",
    },
    {
      image: OtzivCard,
      name: "Зайцева Ольга Дмитриевна",
      rating: 5,
      caption:
        "Очень понравилось качество обслуживания, хочу выразить отдельную благодарность администрации лаборатории",
    },
    {
      image: OtzivCard2,
      name: "Тереньтьева Виктория",
      rating: 4,
      caption:
        "Очень понравилось качество обслуживания, хочу выразить отдельную благодарность администрации лаборатории",
    },
    {
      image: OtzivCard,
      name: "Зайцева Ольга Дмитриевна",
      rating: 4,
      caption:
        "Очень понравилось качество обслуживания, хочу выразить отдельную благодарность администрации лаборатории",
    },
    {
      image: OtzivCard2,
      name: "Тереньтьева Виктория",
      rating: 5,
      caption:
        "Очень понравилось качество обслуживания, хочу выразить отдельную благодарность администрации лаборатории",
    },
    {
      image: OtzivCard,
      name: "Зайцева Ольга Дмитриевна",
      rating: 5,
      caption:
        "Очень понравилось качество обслуживания, хочу выразить отдельную благодарность администрации лаборатории",
    },
    {
      image: OtzivCard2,
      name: "Тереньтьева Виктория",
      rating: 5,
      caption:
        "Очень понравилось качество обслуживания, хочу выразить отдельную благодарность администрации лаборатории",
    },
  ];

  function navigateToElement() {
    const element = document.getElementById("all_usluga");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  function handleScrollToElement() {
    const element = document.getElementById("doctors_usluga");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <>
      {/* /////// First //////// */}

      <div className="home1">
        <div className="container-xl px-0">
          <div className="row">
            <div className="col px-0 col1">
              <div className="col1_content">
                <h1>
                  {t("tech")} <b>{t("labarotory")}</b>
                </h1>
                <p>{t("latest_lab")}</p>
                <button className="homeBtn">{t("equipment")}</button>
              </div>
            </div>
            <div className="col px-0 col2">
              <img src={homeImg} alt="" />
            </div>
          </div>
        </div>
      </div>

      {/* /////// Price //////// */}
      <div className="priceWrap">
        <div className="container-xl price">
          <PriceList />
        </div>
      </div>

      {/* /////// Usluga //////// */}

      <div className="popular_usluga_wrap">
        <div className="container-xl px-0 popular_usluga">
          <div className="usluga_header_text">
            <p>
              Популярные <b>услуги</b>
            </p>
          </div>
          <div className="usluga_cards">
            <div className="row">
              {/* <Link to="/usluga" className='cardWrapper' onClick={navigateToElement}> */}
              <div className="col-6 card">
                <img src={CardImg} alt="" />
                <div className="content_card">
                  <h1>УЗД щитовидной железы</h1>
                  <p>
                    Ультразвуковое исследование сосудов шеи позволяет оценить
                    кровообращение в основных магистралях, питающих головной
                    мозг.
                  </p>
                </div>
              </div>
              {/* </Link> */}
              {/* <Link to="/usluga" className='cardWrapper' onClick={navigateToElement}> */}
              <div className="col-6 card card2">
                <img src={CardImg2} alt="" />
                <div className="content_card">
                  <h1>Общий анализ крови</h1>
                  <p>
                    Общий анализ крови (ОАК-19 показателей) — это наиболее
                    простой и доступный метод первичной оценки состояния
                    здоровья.
                  </p>
                </div>
              </div>
              {/* </Link> */}
              {/* <Link to="/usluga" className='cardWrapper' onClick={navigateToElement}> */}
              <div className="col-6 card card2">
                <img src={CardImg3} alt="" />
                <div className="content_card">
                  <h1>Глюкоза натощак</h1>
                  <p>
                    Анализ крови на глюкозу – рутинное биохимическое
                    исследование, которое используют для диагностики и контроля
                    сахарного диабета.
                  </p>
                </div>
              </div>
              {/* </Link> */}
              {/* <Link to="/usluga" className='cardWrapper' onClick={navigateToElement}> */}
              <div className="col-6 card card2">
                <img src={CardImg4} alt="" />
                <div className="content_card">
                  <h1>МСКТ</h1>
                  <p>
                    Мультиспиральная компьютерная томография (МСКТ) —
                    современный метод точной диагностики, основанный на
                    послойном сканировании организма человека.
                  </p>
                </div>
              </div>
              {/* </Link> */}
            </div>
          </div>
          <Link to="/usluga">
            <button className="uslugaBtn" onClick={navigateToElement}>
              Смотреть все услуги
            </button>
          </Link>
        </div>
      </div>

      {/* /////// Discount //////// */}
      <div className="discountWrap">
        <div className="container-xl px-0 ">
          <h1 className="aksiyaTitle1">Акция</h1>
          <div className="discount">
            <img src={DiscountImg} alt="" />
            <div className="imgdiv"></div>
            <div className="discountContent">
              <h1 className="aksiyaTitle2">Акция</h1>
              <h5>УЗД щитовидной железы бесплатно</h5>
              <p>Каждый день с 15.00 до 7.00</p>
              <button>Подробнее</button>
            </div>
          </div>
        </div>
      </div>

      {/* /////// About ////// */}

      <div className="about">
        <div className="container-xl px-0">
          {/* Top */}

          <div className="topBlock">
            <p className="topBlockTitle">
              Наши <b>направления</b>
            </p>
            <div className="topContent">
              <div className="img2">
                <img src={DocImg2} alt="" />
              </div>
              <div className="buttons">
                <Link to="/usluga" className="w-100">
                  <button className="btn1" onClick={handleScrollToElement}>
                    ЛОР
                  </button>
                </Link>
                <Link to="/usluga" className="w-100">
                  <button onClick={handleScrollToElement}>Терапевт</button>
                </Link>
                <Link to="/usluga" className="w-100">
                  <button onClick={handleScrollToElement}>Гинеколог</button>
                </Link>
                <Link to="/usluga" className="w-100">
                  <button className="btn1" onClick={handleScrollToElement}>
                    Кардиолог
                  </button>
                </Link>
              </div>
              <div className="img">
                <img src={DocImg} alt="" />
              </div>
              <div className="buttons">
                <Link to="/usluga" className="w-100">
                  <button className="btn2" onClick={handleScrollToElement}>
                    Эндокринолог
                  </button>
                </Link>
                <Link to="/usluga" className="w-100">
                  <button onClick={handleScrollToElement}>Уролог</button>
                </Link>
                <Link to="/usluga" className="w-100">
                  <button onClick={handleScrollToElement}>Невропатолог</button>
                </Link>
                <Link to="/usluga" className="w-100">
                  <button className="btn2" onClick={handleScrollToElement}>
                    Гепатолог
                  </button>
                </Link>
              </div>
            </div>
            <Link to="/usluga">
              <button className="topBlockBtn" onClick={handleScrollToElement}>
                Подробнее
              </button>
            </Link>
          </div>

          {/* Questions */}

          <div className="question_block">
            <p>
              Часто задаваемые <b>вопросы</b>
            </p>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Есть ли у нас выезд на забор анализов?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <strong>
                      Мы осуществляем выезд на забор анализов каждый день с 8.00
                      до 11.00. Ограниченем на услугу является выезд на забор
                      бесплатных анализов и на выезд детям до 11 лет. Выезд
                      осуществляется в пределах города Ташкент.
                    </strong>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Как подготовиться к сдаче анализов?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <strong>
                      Для сдачи анализов крови нужно воздержаться от приема
                      жирной, острой, а также вредной пищи. Обязательно
                      приходить на анализы крови после 8 часового голода. Для
                      сдачи анализов микроскопии необходимо исключить
                      мочеиспускание за 2 часа по сдачи анализа и не
                      подмываться.
                    </strong>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Есть ли у нас процедурные кабинеты?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <strong>
                      Процедурный кабинет имеется только в Сергелинском филиале.
                      Осуществляются уколы и капельницы с 9.00 до 15.00 строго
                      по назначению врача.
                    </strong>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo2"
                    aria-expanded="false"
                    aria-controls="collapseTwo2"
                  >
                    Что такое МСКТ?
                  </button>
                </h2>
                <div
                  id="collapseTwo2"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <strong>
                      Многослойная, или по-другому, мультиспиральная
                      компьютерная томография – один из неинвазивных способов
                      диагностики широкого спектра заболеваний нервной,
                      лимфатической, сердечно-сосудистой и других систем. Метод
                      применяют уже больше полувека (с 1992 года), причем число
                      срезов (или слоев) постоянно растет. Аппараты последнего
                      поколения могут создавать до 640 срезов, благодаря чему
                      улучшается визуализация картинок и сокращается время
                      исследования.
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* abouttt clinic */}

          <div className="about_clinic">
            <div className="about_clinic_content">
              <p>
                О <b>Клинике</b>
              </p>
              <div className="about_clinic_img2">
                <img src={TtdFoto2} alt="" />
              </div>
              <h6>
                Добро пожаловать на сайт компании "Toshkent Tibbiy Diagnostika",
                которая предоставляет медицинские услуги в городе Ташкент уже
                более 9 лет. Мы являемся одним из ведущих медицинских центров в
                городе и специализируемся на предоставлении диагностических
                обследований с использованием современного оборудования.
              </h6>
              <button>Подробнее</button>
            </div>
            <div className="about_clinic_img">
              <img src={ClinicImg} alt="" />
            </div>
          </div>
        </div>
      </div>

      {/* //////News////// */}

      <div className="news">
        <div className="container-xl px-0 latest_news">
          <p>
            Последние <b>новости</b>
          </p>
          <div className="cards_news_wrap">
            <div className="news_card">
              <img src={NewsCard} alt="newsImg" />
              <h1>Что делать, если замучила аллергия?</h1>
              <p>
                Новый анализ крови на аллергены теперь доступен в нашей
                лаборатории. Обратитесь к нам, чтобы проверить свою
                чувствительность к различным веществам.
              </p>
            </div>
            <div className="news_card">
              <img src={NewsCard2} alt="newsImg" />
              <h1>Анализ Меди и Цинка?</h1>
              <p>
                Новый анализ крови на аллергены теперь доступен в нашей
                лаборатории. Обратитесь к нам, чтобы проверить свою
                чувствительность к различным веществам.
              </p>
            </div>
            <div className="news_card">
              <img src={NewsCard3} alt="newsImg" />
              <h1>Витамин D?</h1>
              <p>
                Новый анализ крови на аллергены теперь доступен в нашей
                лаборатории. Обратитесь к нам, чтобы проверить свою
                чувствительность к различным веществам.
              </p>
            </div>
          </div>
          <div className="carousel_title">
            <p>
              Отзывы <b>наших пациентов</b>
            </p>
          </div>
        </div>

        <div className="otziv_client">
          <div className="col">
            <Slider {...settings}>
              {data.map((slide, i) => {
                return (
                  <div key={i} className="slide_card">
                    <img
                      className="d-block w-100"
                      src={slide.image}
                      alt="slide"
                    />
                    <h6>{slide.name}</h6>
                    <div className="star_box">
                      <StarRatings
                        rating={slide?.rating}
                        starRatedColor="#F3CD03"
                        numberOfStars={5}
                        name="rating"
                      />
                    </div>
                    <p>{slide.caption}</p>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>

      {/* contact */}
      <div className="contactWrapper">
        <div className="contact">
          <h1>Контакты</h1>
          <div className="map">
            <Map />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
