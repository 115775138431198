import React, { useEffect } from "react";
import "./usluga.scss";
import ImgUsluga from "../../assets/images/page2/main.svg";
import Pricelist from "../../component/priceList";
import UslugaImg1 from "../../assets/images/page2/u7.svg";
import UslugaImg2 from "../../assets/images/page2/u4.svg";
import UslugaImg3 from "../../assets/images/page2/u5.svg";
import UslugaImg4 from "../../assets/images/page2/u2.svg";
import UslugaImg5 from "../../assets/images/page2/u6.svg";
import UslugaImg6 from "../../assets/images/page2/u3.svg";
import UslugaImg7 from "../../assets/images/page2/u1.svg";
import Doc1 from "../../assets/img/doc1.png";
import Doc2 from "../../assets/img/doc2.png";
import Doc3 from "../../assets/img/doc3.png";
import Doc4 from "../../assets/img/doc4.png";
import Doc5 from "../../assets/img/doc5.png";
import Doc6 from "../../assets/img/doc6.png";
import Doc7 from "../../assets/img/doc7.png";
import Doc8 from "../../assets/img/doc8.png";
import Doc9 from "../../assets/img/doc9.png";
import Doc10 from "../../assets/img/doc10.png";
import Doc11 from "../../assets/img/doc11.png";
import Doc12 from "../../assets/img/doc12.png";
import Doc13 from "../../assets/img/doc13.png";
import Doc14 from "../../assets/img/doc14.png";
import Doc15 from "../../assets/img/doc15.png";
import Doc16 from "../../assets/img/doc16.png";
import Doc17 from "../../assets/img/doc17.png";
import Doc18 from "../../assets/img/doc18.png";
import Doc19 from "../../assets/img/doc19.png";
import Doc20 from "../../assets/img/doc20.png";
import Doc21 from "../../assets/img/doc21.png";
import Doc22 from "../../assets/img/doc22.png";
import Doc23 from "../../assets/img/doc23.png";
function Usluga() {
  const data = [
    {
      image: UslugaImg1,
      usluga_name: "Лабораторная диагностика",
      caption: "Лабораторная диагностика",
    },
    {
      image: UslugaImg2,
      usluga_name: "Ультразвуковая диагностика",
      caption:
        "Узд (ултразвуковая диагностика)- ето неиҳвазиний метод обследования человеческого организма на основе исползования високочастотних звукових волн.",
    },
    {
      image: UslugaImg3,
      usluga_name: "Мультиспиральная компьютерная томография",
      caption:
        "Мультиспиральная компьютерная томография (МСКТ) – является самым современным методом для проведения визуальной диагностики структурных изменений внутренних тканей и органов и функциональных систем организма.",
    },
    {
      image: UslugaImg4,
      usluga_name: "Электрокардиография",
      caption:
        "Электрокардиогра́фия (ЭКГ) — методика регистрации и исследования электрических полей, образующихся при работе сердца.",
    },
    {
      image: UslugaImg5,
      usluga_name: "Спирометрия, спирография",
      caption:
        "Спирометрия, спирография — метод исследования функции внешнего дыхания, включающий в себя измерение объёмных и скоростных показателей дыхания.",
    },
    {
      image: UslugaImg6,
      usluga_name: "Кольпоскоптия",
      caption:
        "Кольпоскопия — диагностический осмотр входа во влагалище, стенок влагалища и влагалищной части шейки матки при помощи кольпоскопа — специального прибора, представляющего собой бинокуляр и осветительный прибор.",
    },
    {
      image: UslugaImg7,
      usluga_name: "Магнитотерапия",
      caption:
        "Магнитотерапия — это популярный физиотерапевтический прием, основанный на влиянии низкочастотных магнитных полей переменного или постоянного действия на проблемную зону организма.",
    },
  ];

  const docData = [
    {
      image: Doc1,
      name: "Бабаджанов Алишер Фахрутдинович",
      caption: "Дермато-венеролог врач высшей категории ",
    },
    {
      image: Doc2,
      name: "Мирсодикова Дурдона Фахритдиновна",
      caption: "Пренвентив интегратив гинеколог",
    },
    {
      image: Doc3,
      name: "Гиргин Эльвира Серьяновна",
      caption: "Дерматовенеролог",
    },
    {
      image: Doc4,
      name: "Джураева Мадина Одилжановна",
      caption: "Врач Узи",
    },
    {
      image: Doc5,
      name: "Ходжаева Юлдузхон Рустамовна",
      caption: "Гинеколог",
    },
    {
      image: Doc6,
      name: "Рахимова Зиёда Равшановна",
      caption: "Аллерголог",
    },
    {
      image: Doc7,
      name: "Матгазиева Дилфуза Хусановна",
      caption: "Превентив Кардиотерапевт",
    },
    {
      image: Doc8,
      name: "Абдумажитова Феруза Шамсиевна",
      caption: "Кардиолог",
    },
    {
      image: Doc9,
      name: "Недзведская Юлия Вячеславовна",
      caption: "Пульманолог-Аллерголог",
    },
    {
      image: Doc10,
      name: "Султонбоев Улугбек Мирахмедович",
      caption: "Лор ",
    },
    {
      image: Doc11,
      name: "Райко Ангелина Игнатьевна",
      caption: "Дерматокосметолог-Трихолог-Дерматовенеролог",
    },
    {
      image: Doc12,
      name: "Нуманов Зохид Саитзуфарович",
      caption: "Врач Узи",
    },
    {
      image: Doc13,
      name: "Раззаков Азамат Жураевич",
      caption: "Лор",
    },
    {
      image: Doc14,
      name: "Исмаилов Аскар Мехриддинович",
      caption: "Уролог",
    },
    {
      image: Doc15,
      name: "Омонов Акромжон Турсуналиевич",
      caption: "Лор врач",
    },
    {
      image: Doc16,
      name: "Нурмухаммедов Адхам Абдухамидович",
      caption: "Лор врач",
    },
    {
      image: Doc17,
      name: "Сирожиддинова Зумрад Азизхажаевна",
      caption: "Терапевт",
    },
    {
      image: Doc18,
      name: "Рафикова Гуля Шахидовна",
      caption: "Эндокринолог",
    },
    {
      image: Doc19,
      name: " Белялова Эльзара Рустемовна",
      caption: "Врач Узи",
    },
    {
      image: Doc20,
      name: "Шарофбоев Шохжахон Кахрамонович",
      caption: "Лор врач",
    },
    {
      image: Doc21,
      name: "Аманова Гульчехра Камильжановна",
      caption: "Врач Узи",
    },
    {
      image: Doc22,
      name: "Вохобов Аббосхон Баходирович",
      caption: "Лор врач",
    },
    {
      image: Doc23,
      name: "Расулова Мухлиса Асроровна",
      caption: "Невролог-Педиатр",
    },
  ];

  // useEffect(() => {
  //     const shouldScroll = true;

  //     if (shouldScroll) {
  //       const element = document.getElementById('doctors_usluga');
  //       if (element) {
  //         element.scrollIntoView({ behavior: 'smooth' });
  //       }
  //     }
  //   }, []);

  return (
    <>
      <div className="uslugaPage">
        <div className="uslugaPageTop">
          <div className="container-xl px-0">
            <div className="rowUslugaTop">
              <div className="contentUsluga">
                <p>
                  Ваше здоровье - <br /> <b>наш приоритет</b>
                </p>
                <h6>
                  Мы используем только лучшее оборудование и современные методы
                  обработки анализов{" "}
                </h6>
              </div>
              <div className="imgUsluga">
                <img src={ImgUsluga} alt="" />
              </div>
            </div>
          </div>
        </div>

        {/* /////// */}
        <Pricelist />

        {/* ///////// */}
        <div className="allUslugaWrapper" id="all_usluga">
          <div className="container-xl px-0 allUsluga">
            <p className="uslugaTitile">
              Все <b>услуги</b>
            </p>
            <div className="usluga_cards">
              <div className="slide_card row">
                {data.map((slide, i) => {
                  return (
                    <div className="col-5">
                      <img
                        className="d-block w-100"
                        src={slide.image}
                        alt="slide"
                      />
                      <div className="card_content">
                        <h6>{slide.usluga_name}</h6>
                        <p>{slide.caption}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="ourDocWrap" id="doctors_usluga">
            <div className="container-xl">
              <div className="ourDoctors">
                <p className="docTitle">
                  Наши <b>врачи</b>
                </p>
                <div className="doc_cards">
                  <div className="doc_cards_row row">
                    {docData.map((slide, i) => {
                      return (
                        <div className="col-4" key={i}>
                          <img
                            className="d-block w-100"
                            src={slide.image}
                            alt="slide"
                          />
                          <div className="card_content">
                            <h6>{slide.caption}</h6>
                            <p>{slide.name}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Usluga;
