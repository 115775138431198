import React, { Suspense, useState, useEffect } from 'react';
import './App.scss';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer
import 'react-toastify/dist/ReactToastify.css';
import PhoneIcon from "./assets/img/перезвон.png";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./pages/navbar/navbar";
import Footer from "./pages/footer/footer";
import Home from "./pages/home/home";
import About from "./pages/about/about";
import Usluga from "./pages/usluga/usluga";
import Fotogallery from "./pages/fotogallery/fotogallery";
import Aksiya from "./pages/aksiya/aksiya";
import Contact from "./pages/contact/contact";
import Animation from "./pages/animation/animation";
import ModalImg from "./assets/img/pose_8.png";

function App() {
  const { t, i18n } = useTranslation();
  const [loader, setLoader] = useState(true);
  const [phoneNumberInput, setPhoneNumberInput] = useState('');
  useEffect(() => {
    if (!sessionStorage.getItem('logIn')) {
      setLoader(false);
      sessionStorage.setItem('logIn', true);
      setTimeout(() => {
        setLoader(true);
      }, 3000);
    }
  }, []);

  const [show4, setShow4] = useState(false);

  let tg = {
    token: "5651583234:AAFqZyj6vrZ8uURN8PkA0GMPl0YWla4Cu2o",
    chat_id: "-1001677687738"
}

function sendMessage(text) {
  const tgUrl = `https://api.telegram.org/bot${tg.token}/sendMessage`;
  const corsAnywhereUrl = 'https://cors-anywhere.herokuapp.com/';

  const params = new URLSearchParams({
      chat_id: tg.chat_id,
      text: text
  });

  fetch(`${corsAnywhereUrl}${tgUrl}?${params.toString()}`)
      .then(response => {
          if (!response.ok) {
              throw new Error('Failed to send message');
          }
      })
      .catch(error => {
          console.error('Error sending message to Telegram:', error);
      });
}



  const closeModal4 = () => {
    setShow4(false);
  };
  const saveModal4 = (e) => {
    e.stopPropagation();
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumberInput(e.target.value);
  };

  const sendPhoneNumber = () => {
    const apiUrl = 'https://backend.ttd.uz/api/send.php';

    if (phoneNumberInput.length === 9) {
      fetch(`${apiUrl}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phoneNumberInput: phoneNumberInput }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            toast.success('Спасибо за обращение! Мы свяжемся с вами в ближайшее время');
            setShow4(false);
          } else {
            toast.error('Ошибка отправки номера телефона. Пожалуйста, попробуйте снова.');
          }
        })
        .catch((error) => {
          console.error('Error sending phone number:', error);
          toast.error('Ошибка отправки номера телефона.');
        });
    } else {
      toast.error('Пожалуйста введите номер телефона правильно');
    }
    setShow4(false);
  };

  return (
    <>
      <ToastContainer /> {/* Initialize ToastContainer */}
      {loader ? (
        <div className="App">
          <div className="fixed-icon-container">
            <img className="rotate-icon" onClick={() => setShow4(true)} src={PhoneIcon} alt='phone'/>
          </div>
          {show4 ? (
            <div className="home_modal" onClick={closeModal4}>
              <div className="home_modal_inner" onClick={saveModal4}>
                <>
                  {/* <div className="home_modal_header">
                    <p className="modal_close_icon" onClick={closeModal4} style={{ color: 'aliceblue' }}>X</p>
                  </div> */}
                  <div className="form-group home_modal_body">
                    <p className="modal_close_icon" onClick={closeModal4} style={{ color: 'aliceblue' }}>X</p>
                    <div className="popup-book_item row">
                      <div className="modal_content">
                        <h1>{t('yes?')} <b>{t('questions')}</b></h1>
                        <p>{t('take_contact')}</p>
                        <div className="nummmPhone">
                          <p>+998</p>
                          <input
                            name="phoneNumber"
                            type='number'
                            placeholder='99_999_99_99'
                            className="passwordLogin"
                            autoComplete="off"
                            value={phoneNumberInput}
                            onChange={handlePhoneNumberChange}
                            required
                            // max='9'
                            maxLength='9'
                            aria-label="Please enter your phone number"
                            id="phone-input"
                          />
                        </div>
                        <button onClick={sendPhoneNumber}>{t('call_me')}</button>
                      </div>
                      <div className="img_modal">
                        <img src={ModalImg} alt="" />
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          ) : null}
          {/* Modaaaaalll */}
          <BrowserRouter>
            <Navbar />
            <Suspense fallback={<Animation />}>
              <Routes>
                <Route exact path='/' element={<Home />} />
                <Route exact path='/about' element={<About />} />
                <Route exact path='/usluga' element={<Usluga />} />
                <Route exact path='/gallery' element={<Fotogallery />} />
                <Route exact path='/aksiya' element={<Aksiya />} />
                <Route exact path='/contact' element={<Contact />} />
              </Routes>
            </Suspense>
            <Footer />
          </BrowserRouter>
        </div>
      ) : (<Animation />)}
    </>
  );
}

export default App;

// import React, { useState, useEffect } from 'react';
// import './App.scss';
// import { useTranslation } from 'react-i18next';
// import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer
// import 'react-toastify/dist/ReactToastify.css';
// import PhoneIcon from "./assets/img/перезвон.png";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Navbar from "./pages/navbar/navbar";
// import Footer from "./pages/footer/footer";
// import Home from "./pages/home/home";
// import About from "./pages/about/about";
// import Usluga from "./pages/usluga/usluga";
// import Fotogallery from "./pages/fotogallery/fotogallery";
// import Aksiya from "./pages/aksiya/aksiya";
// import Contact from "./pages/contact/contact";
// import Animation from "./pages/animation/animation";
// import ModalImg from "./assets/img/pose_8.png";

// function App() {
//   const { t, i18n } = useTranslation();
//   const [loader, setLoader] = useState(true);
//   const [phoneNumberInput, setPhoneNumberInput] = useState('');
//   useEffect(() => {
//     if (!sessionStorage.getItem('logIn')) {
//       setLoader(false);
//       sessionStorage.setItem('logIn', true);
//       setTimeout(() => {
//         setLoader(true);
//       }, 3000);
//     }
//   }, []);

//   const [show4, setShow4] = useState(false);

//   const tg = {
//     token: "5651583234:AAFqZyj6vrZ8uURN8PkA0GMPl0YWla4Cu2o",
//     chat_id: "-1001677687738" 
//   };

//   const sendMessageToTelegram = (text) => {
//     const tgUrl = `https://api.telegram.org/bot${tg.token}/sendMessage`;

//     const params = new URLSearchParams({
//       chat_id: tg.chat_id,
//       text: text
//     });

//     fetch(`${tgUrl}?${params.toString()}`)
//       .then(response => {
//         if (!response.ok) {
//           throw new Error('Failed to send message');
//         }
//         toast.success('Message sent to Telegram successfully!');
//       })
//       .catch(error => {
//         console.error('Error sending message to Telegram:', error);
//         toast.error('Failed to send message to Telegram.');
//       });
//   };

//   const closeModal4 = () => {
//     setShow4(false);
//   };

//   const handlePhoneNumberChange = (e) => {
//     setPhoneNumberInput(e.target.value);
//   };

//   const sendPhoneNumber = () => {
//     const phoneNumber = "+998" + phoneNumberInput;
//     sendMessageToTelegram(`New phone number: ${phoneNumber}`);
//     setShow4(false);
//   };

//   return (
//     <>
//       <ToastContainer /> {/* Initialize ToastContainer */}
//       {loader ? (
//         <div className="App">
//           <div className="fixed-icon-container">
//             <img className="rotate-icon" onClick={() => setShow4(true)} src={PhoneIcon} alt='phone'/>
//           </div>
//           {show4 ? (
//             <div className="home_modal" onClick={closeModal4}>
//               <div className="home_modal_inner" onClick={() => {}}>
//                 <div className="form-group home_modal_body">
//                   <p className="modal_close_icon" onClick={closeModal4} style={{ color: 'aliceblue' }}>X</p>
//                   <div className="popup-book_item row">
//                     <div className="modal_content">
//                       <h1>{t('yes?')} <b>{t('questions')}</b></h1>
//                       <p>{t('take_contact')}</p>
//                       <div className="nummmPhone">
//                         <p>+998</p>
//                         <input
//                           name="phoneNumber"
//                           type='number'
//                           placeholder='99_999_99_99'
//                           className="passwordLogin"
//                           autoComplete="off"
//                           value={phoneNumberInput}
//                           onChange={handlePhoneNumberChange}
//                           required
//                           maxLength='9'
//                           aria-label="Please enter your phone number"
//                           id="phone-input"
//                         />
//                       </div>
//                       <button onClick={sendPhoneNumber}>{t('call_me')}</button>
//                     </div>
//                     <div className="img_modal">
//                       <img src={ModalImg} alt="" />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ) : null}
//           {/* Routing and other components */}
//         </div>
//       ) : (<Animation />)}
//     </>
//   );
// }

// export default App;
